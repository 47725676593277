<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {dateUtil} from "@/helpers/date-util";
import {currencyHelper} from "@/helpers/currency-helper";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {swalHelper} from "@/helpers/swal-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import CreateOrderModal from "@/components/order/create-order-modal.vue";
import Swal from "sweetalert2";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";


export default {

  components: {

    CustomCardSubtitle,
    Layout,
    PageHeader,
    CreateOrderModal
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    },

    currencyHelper() {
      return currencyHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      products: null,
      users: null
    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.marketplace.orders.title'),
          active: true
        }
      ]
    },

    async loadOrders() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/marketplace/order/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage,
            "forUser": false
          }
        });

        const {marketplaceOrders, count} = data

        this.table.items = marketplaceOrders
        this.table.totalRows = count
        this.table.rows = count

        if (this.mounted) {
          this.$refs.table.refresh()
        }

        await this.loadProducts()
        await this.loadUsers()
        return this.table.items;
      } catch (error) {
        console.log(error)
        return []
      }
    },

    async loadUsers() {
      if (this.table.items.length === 0) {
        return;
      }

      const ids = [];
      for (let marketplaceOrder of this.table.items) {
        ids.push(marketplaceOrder.userId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const {data} = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(data.map((obj) => [obj.id, obj]));
    },

    async loadProducts() {
      const ids = [];
      for (let marketplaceOrder of this.table.items) {
        ids.push(marketplaceOrder.id);
      }

      const {data} = await axios.get(`/marketplace/order/products`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "orderIds": ids.join(","),
        }
      });

      this.products = new Map(data.map((obj) => [obj.marketplaceOrderId, obj]));
    },

    getProduct(marketplaceOrderId) {
      if (!this.products) {
        return null
      }

      return this.products.get(marketplaceOrderId) || null
    },

    getUser(userId) {
      if (!this.users) {
        return null
      }

      return this.users.get(userId) || null
    },

    setPaid(marketplaceOrder) {
      swalHelper.yesOrNo(() => {
        const json = JSON.stringify(marketplaceOrder)
        axios.post(`/marketplace/order/manual-success`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then((result) => {
          Swal.fire("Sukces!", 'Pomyślnie ustawiono płatność jako opłaconą', "success").then(() => {
            for (const index in this.table.items) {
              if (this.table.items[index].id === marketplaceOrder.id) {
                this.table.items[index] = result.data
                break;
              }
            }

            this.$refs.table.refresh()
          });
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      })
    },

    getFields() {
      return [
        {
          key: 'createdAt', label: 'Utworzono', formatter: value => {
            if (!value) {
              return "-"
            }

            return dateUtil.asDateTime(value)
          }
        },
        {key: "product", slot: true, label: 'Produkt'},
        {key: "user", slot: true, label: 'Użytkownik'},
        {
          key: "status", label: 'Status zamówienia', formatter: value => {
            return this.$t('marketplace.order.status.' + value)
          }
        },
        {key: "payment_status", slot: true, label: 'Status płatności'},
        {key: "action", slot: true, label: 'Akcje'}
      ]
    }

  },

  async created() {
    await this.loadOrders()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.marketplace.orders.title')" :items="getItems()"/>
    <create-order-modal ref="createOrderModal"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <b-button variant="success" @click="$refs.createOrderModal.openModal($refs.table.refresh())">Stwórz
                  darmowe zamówienie
                </b-button>
              </div>
            </div>

            <template v-if="table.items">
              <ecat-table
                  :table="table"
                  :fields="getFields()"
                  :items="loadOrders"
                  :pagination-top="true"

              >
                <template v-slot:payment_status="{ item }">
                  <span>{{ item.status === 'ORDER_COMPLETED' ? "Opłacone" : "Nieopłacone" }}</span>
                </template>

                <template v-slot:action="{ item }">
                  <b-button v-if="item.status !== 'ORDER_COMPLETED'" variant="primary" @click="setPaid(item)">Ustaw jako
                    zapłacone
                  </b-button>
                </template>

                <template v-slot:product="{ item }">
                    <span :set="product = getProduct(item.id)">
                      {{ product ? product.title : "" }}
                    </span>
                </template>

                <template v-slot:user="{ item }">
                    <span :set="user = getUser(item.userId)">
                      {{ user ? user.firstName + " " + user.lastName + " (" + user.email + ")" : "" }}
                    </span>
                </template>
              </ecat-table>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>